// scan edit
.scan-service-edit {
    font-family: 'Poppins', sans-serif;
    .btn-row{
      display: none;
    }
    .request-id {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 32px;
      @media #{$information-xsmall-tab}{
        flex:0 0 100%;
      }
      @media #{$information-mob-iphone8} {
        margin-bottom: 24px;
      }
      .title {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.1;
      }
      .num {
        font-size: 24px;
        font-weight: 600;
      }
    }
    &_header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media #{$information-xsmall-tab}{
        flex-wrap: wrap;
      }
    }
    @media #{$information-table}{
      padding-top: 20px;
    }
    @media #{$information-xsmall-tab}{
      padding: 20px 25px 0;
      .btn-row{
        display: flex;
      }
    }
    @media #{$information-mob-iphone8}{
      padding: 20px 0 0;
    }
}
  
.scan-details {
    margin-bottom: 55px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #A3A4A6;
    &_item {
      cursor: pointer;
      position: relative;
      padding: 8px 52px 8px 22px;
      transition: background .3s ease-in;
      .tooltip-mask{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &.disabled{
        background: #BDC0C8;
        &::before{
          content: '';
        }
      }
      &:hover{
        background: #F2F2F4;
      }
      &::before{
        content: "\e981";
        font-family: 'icomoon' !important;
        font-size: 24px;
        height: 24px;
        position: absolute;
        top:50%;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: center;
        right: 24px;
        color:#242F50;
      }
      & + .scan-details_item{
        border-top: 1px solid rgba(#000,0.25);
      }
      .payment-doc{
        padding-bottom: 8px;
        justify-content: center;
        & > div{
          margin: 0 32px;
        }
        @media #{$information-mob} {
          margin: 0 -26px;
          & > div{
            margin: 0 16px;
          }
        }
        @media #{$information-mob-iphone10} {
          margin: 0 -36px;
          & > div{
            margin: 0 6px;
          }
        }
        @media #{$information-mob-iphone10} {
          margin: 0 -36px;
          & > div{
            margin: 0 6px;
            max-width: 120px;
          }
        }
      }
      &_value{
        font-size: 16px;
        margin-bottom: 8px;
        color:rgba($base, 0.5);
      }
      .edit-input {
        font-size: 14px;
        font-weight: 500;
        color:rgba($base, 0.65);
        text-decoration: underline;
      }
      .input-wrap {
        position: relative;
        width: 100%;
        margin-bottom: 0px;
        color:rgba($base, 0.5);
        .data-text{
          color:$base;
        }
        &-field{
          width: 100%;
          display: block;
          resize: none;
          font: 400 16px/1.5 'Poppins', sans-serif;
        }
        &.input-file-wrap {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            height: 24px;
            width: 24px;
            background: url(../assets/svg/sprite-sidebar.svg);
            background-repeat: no-repeat;
            background-position: 0 -24px;
          }
          input {
            padding-left: 55px;
            @media #{$information-mob-iphone10} {
              padding-left: 50px;
            }
          }
        }
        label {
          pointer-events: none;
          white-space: nowrap;
          margin-bottom: 0;
          padding: 0 8px;
          background: #fefefe;
          z-index: 2;
          top: 17px;
          transition: font-size 0.3s ease-out, top 0.3s ease-out;
          top: -12px;
          left: 8px;
          position: absolute;
          color:rgba($base, 0.65);
          font: 500 14px/24px 'Montserrat', sans-serif;
        }
        input {
          height: 56px;
          line-height: 24px;
          width: 100%;
          font-size: 16px;
          padding: 16px 52px 16px 16px;
          border-radius: 4px;
          border: 1px solid #babbbe;
          color: $base;
          @media #{$information-mob-iphone8} {
            font-size: 14px;
          }
        }
      }
      &_title {
        color:rgba($base, 0.65);
        font: 700 14px/1.4 'Montserrat', sans-serif;
        margin-bottom: 8px;
      }
      .appointment-field {
        margin-bottom: 16px;
        @media #{$information-mob-iphone8} {
          font-size: 14px;
        }
      }
      @media #{$information-xsmall-tab}{
        padding: 8px 40px 8px 16px;
        &::before{
          right: 16px;
        }
      }
    }
    @media #{$information-mob-iphone10} {
      margin-bottom: 30px;
    }
}