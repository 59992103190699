.global-title_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  .btn-scan {
    height: 40px;
    background: #244c4f;
    font-weight: 500;
    padding: 8px 22px;
    line-height: 24px;
    border-color: #244c4f;
    margin-top: auto;
    white-space: nowrap;
    &:hover {
      background: #577274;
      color: #fefefe;
      border-color: #577274;
    }
  }
  .sub-title {
    color: #ff3554;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.6px;
    margin-bottom: 12px;
    text-transform: uppercase;
    @media #{$information-mob-iphone8} {
      font-size: 14px;
    }
  }
  .text {
    max-width: 460px;
    font-size: 18px;
    margin-bottom: 15px;
    padding-top: 24px;
    @media #{$information-middle-tab} {
      margin-bottom: 25px;
    }
  }
  @media #{$information-middle-tab} {
    &_box {
      max-width: 460px;
    }
    .h1 {
      font-size: 42px;
    }
  }
  @media #{$information-xsmall-tab}{
    &_box {
      width: 100%;
      max-width: 100%;
    }
    .sub-title {
      text-align: center;
    }
    .h2{
      text-align: center;
    }
  }
  @media #{$information-mob} {
    margin-bottom: 10px;
    flex-wrap: wrap;
    .btn-scan {
      margin: 26px auto 0 0;
    }
    .h1 {
      font-size: 48px;
    }
  }
  @media #{$information-mob-iphone8} {
    .h1{
      font-size: 42px;
    }
  }
}


@keyframes rotateCircle {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
}

.trust {
  &_item {
    padding: 0 15px;
    font-size: 18px;
    counter-increment: trust_counter;
    .wrap {
      padding-left: 64px;
      position: relative;
      &::before {
        content: counter(trust_counter);
        background: #ff8a35;
        border-radius: 50px;
        font: 700 24px/32px 'Playfair Display', serif;
        height: 40px;
        width: 40px;
        position: absolute;
        left: 0;
        top: 0;
        color: $base;
        text-align: center;
      }
    }
    .h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
  }
}

.dashboard-icon {
  margin-bottom: 0px;
  width: 100px;
  height: 100px;
  background: url(../assets/svg/sprite-scan-manager-style-icons.svg);
  &.tell {
    background-position: 0 0;
  }
  &.scheduled {
    background-position: 0 -100px;
  }
  &.scan {
    background-position: 0 -200px;
  }
  &.info{
    background-position: 0 -300px;
  }
  &.post{
    width: 120px;
    height: 120px;
    background-position: 0 -400px;
  }
}


// dashboard page
  
.dashboard {
    flex: 1;
    background: #F8F9FA;
    @media #{$information-notebook} {
      padding-left: 72px;
    }
    @media #{$information-table} {
      padding-left: 0;
    }
    .container {
      max-width: 1250px;
      padding: 0px 70px;
      @media #{$information-laptop} {
        padding: 0 30px;
        max-width: 1170px;
      }
      @media #{$information-table} {
        padding: 0 30px;
      }
      @media #{$information-mob-iphone8} {
        padding: 0 15px;
      }
    }
    &_banner {
      padding: 80px 0 108px;
      background-color: #F8F9FA;
      background-position: right bottom;
      position: relative;
      .container{
        position: relative;
        z-index: 1;
      }
      .figure{
        right: 5%;
        bottom: 65px;
        z-index: 0;
        position: absolute;
        border: 8px solid #A7B7B9;
        border-radius: 50%;
        width: 141px;
        height: 141px;
        .circle{
          width: 191px;
          height: 191px;
          position: absolute;
          left: -31px;
          top: -31px;
          animation: rotateCircle 55s linear infinite;
          transform-origin: center;
          &::before{
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #FF3554;
            border-radius:50%;
          }
        }
        @media #{$information-xsmall-tab}{
          display: none;
        }
      }
      .row{
        justify-content: space-between;
      }
      &_info{
        flex:0 0 408px;
        max-width: 408px;
        padding: 32px 24px;
        margin-right: -40px;
        background: #FEFEFE;
        box-shadow: 0px 20px 70px -30px rgba(36, 47, 80, 0.3);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        .dashboard-icon{
          flex:0 0 100px;
        }
        .btn{
          margin-left: auto;
          font: 600 18px/36px "Poppins", sans-serif;
          font-weight: 600;
          height: 57px;
          border: 1px solid #040508;
        }
        .row{
          margin-bottom: 32px;
        }
        .text{
          padding-left: 16px;
          p{
            font: 400 18px/1.4 'Montserrat', sans-serif;
          }
        }
        .title{
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 24px;
          line-height: 1.4;
        }
        @media #{$information-notebook}{
          margin-right: 0;
          height: 100%;
          flex:0 0 340px;
          max-width: 340px;
          padding: 28px 20px;
          .row{
            margin-bottom: 32px;
          }
          .title{
            font-size: 20px;
          }
          .text{
            p{
              font: 400 16px/1.4 'Montserrat', sans-serif;
            }
          }
        }
        @media #{$information-table}{
          flex:0 0 270px;
          max-width: 270px;
          padding: 25px 18px;
          .dashboard-icon{
            flex:0 0 50px;
            height: 50px;
            width: 50px;
            background-size: 60px 260px;
            &.info{
              background-position: 0 -150px;
            }
          }
        }
        @media #{$information-xsmall-tab}{
          flex:0 0 auto;
          padding: 25px 41px;
          margin: 48px -30px -48px;
          max-width: none;
          box-shadow: none;
          border-radius: 0;
          .btn{
            margin: 0 auto;
            height: 82px;
            width: 100%;
            max-width: 244px;
            font: 600 24px/60px "Poppins", sans-serif;
          }
          .title{
            font-size: 24px;
          }
          .text{
            font-size: 18px;
            padding: 0;
            text-align: center;
          }
          .row{
            align-items: center;
          }
          .dashboard-icon{
            width: 100px;
            height: 100px;
            flex:0 0 100px;
            margin-bottom: 16px;
            background-size: auto auto;
            &.info{
              background-position: 0 -300px;
            }
          }
        }
      }
      &_box {
        margin-right: 30px;
        .btn {
          max-width: 327px;
          width: 100%;
          letter-spacing: 0.8px;
          color: #FEFEFE;
          height: 82px;
          font: 600 24px/62px "Poppins", sans-serif;
          &:hover {
            color: #fefefe;
          }
        }
      }
      @media #{$information-small-notebook}{
        padding: 80px 0;
      }
      @media #{$information-notebook} {
        &_box {
          .btn {
            height: 60px;
            max-width: 280px;
            font-size: 20px;
            line-height: 40px;
          }
        }
      }
      @media #{$information-xsmall-tab}{
        padding: 48px 0;
        &_box{
          margin: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          .btn {
            height: 82px;
            font-size: 24px;
            max-width: 327px;
            width: 100%;
            line-height: 60px;
          }
        }
        .row{
          flex-direction: column;
        }
      }
    }
    &-icon {
      margin-bottom: 32px;
    }
    & .trust {
      background: #fff;
      padding: 70px 0 60px;
      .container{
        display: flex;
        flex-direction: column;
        @media #{$information-table} {
          padding: 0 41px;
          .row{
            margin: 0;
          }
        }
        @media #{$information-mob-iphone8} {
          padding: 0 15px;
        }
      }
      .link{
        margin-left: auto;
        cursor: pointer;
        color: #0F2ED9;
        font-size: 18px;
        transition: opacity .4s $easing;
        padding-right: 40px;
        position: relative;
        &:hover{
          opacity: 0.6;
        }
        &::before{
          content: "";
          position: absolute;
          top:0px;
          right: 0;
          width: 24px;
          height: 25px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 5L13 3.5L21 11.5H18L11.5 5Z' fill='%230F2ED9'/%3E%3Cpath d='M11.5 20L13 21.5L21 13.5H18L11.5 20Z' fill='%230F2ED9'/%3E%3Crect x='21' y='11.5' width='2' height='18' transform='rotate(90 21 11.5)' fill='%230F2ED9'/%3E%3C/svg%3E%0A");
        }
        @media #{$information-mob-iphone8}{
          font-size: 16px;
          padding-right: 30px;
        }
      }
      .h1 {
        margin-bottom: 0px;
        letter-spacing: -0.1px;
        font: 700 36px/1.2 'Playfair Display', serif;
      }
      &_items {
        margin: 0 -15px 35px;
        counter-reset: trust_counter;
        @media #{$information-table} {
          flex-wrap: wrap;
        }
      }
      &_item {
        @media #{$information-notebook} {
          font-size: 16px;
        }
        @media #{$information-table} {
          flex: 0 0 100%;
          padding: 0;
          max-width: 100%;
          margin-bottom: 45px;
          .wrap {
            padding-left: 0;
            &::before{
              display: none;
            }
          }
        }
      }
      .global-title_wrap {
        margin-bottom: 79px;
        .sub-title {
          color: #ff8a35;
        }
        @media #{$information-notebook} {
          margin-bottom: 50px;
        }
      }
      @media #{$information-notebook} {
        padding-bottom: 70px;
      }
      @media #{$information-table} {
        padding-bottom: 40px;
      }
    }
}


// blog style

.blog{
  &-section{
    padding: 68px 0 38px;
    background: #F8F9FA;
    .global-title_wrap{
      margin-bottom: 68px;
      text-align: center;
      display: flex;
      justify-content: center;
      &_box{
        .sub-title{
          margin-bottom: 0;
          color:#FF8A35;
        }
      }
    }
    @media(max-width:639px){
      .container{
        padding: 0 41px;
      }
    }
  }
  &-list{
    margin: 0 -16px;
    flex-wrap: wrap;
    &_item{
      flex:0 0 33.333%;
      padding: 0 16px;
      margin-bottom: 56px;
      &:nth-child(2){
        order: 1;
      }
      &:nth-child(3){
        order: 2;
      }
      &:nth-child(1n+4){
        order: 4;
      }
      &.contact{
        order: 3;
        .blog-list_item-wrap{
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #FF8A35;
          padding: 32px 24px 48px;
          border-radius: 16px;
          box-shadow: 0px 20px 70px -30px rgba(36, 47, 80, 0.3);
          .btn{
            background: none;
            color:#fff;
            font: 600 18px/36px "Poppins", sans-serif;
            border-color: #fff;
            margin-top: auto;
            &:hover{
              background: #fff;
              color: #242F4F;
            }
          }
          .title{
            font-weight: 600;
            font-size: 24px;
            max-width: 215px;
            margin: auto 0;
            line-height: 140%;
            text-align: center;
            color: #FEFEFE;
            margin-bottom: 32px;
          }
        }
      }
      &-wrap{
        padding-bottom: 20px;
        .title{
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 16px;
        }
        .link{
          font-size: 18px;
          line-height: 140%;
          text-decoration: underline;
          color: #0F2ED9;
          display: table;
          &:hover{
            text-decoration: none;
          }
        }
        .text{
          margin-bottom: 16px;
          color: #242F4F;
          font-size: 14px;
        }
        .img{
          margin-bottom: 16px;
          display: block;
          border-radius: 16px;
          background: url(../assets/img/mask-blog.png);
          width: 100%;
          min-height: 241px;
          overflow: hidden;
          background-size: cover;
          background-position: center;
        }
      }
      @media(max-width:959px){
        flex:0 0 50%;
        max-width: 50%;
      }
      @media(max-width:639px){
        flex:0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

// doctor

.doctor{
  &-section{
    padding: 70px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='1467' height='406' viewBox='0 0 1467 406' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1451' cy='16' r='16' transform='rotate(-90 1451 16)' fill='%23FF8A35'/%3E%3Ccircle opacity='0.15' r='74' transform='matrix(-1 0 0 1 74 332)' fill='%23FF8A35'/%3E%3Ccircle opacity='0.35' r='46.5' transform='matrix(-1 0 0 1 74.5 332.5)' fill='%23FF8A35'/%3E%3Ccircle r='20' transform='matrix(-1 0 0 1 75 333)' fill='%23FF8A35'/%3E%3C/svg%3E%0A");
    .row{
      justify-content: space-between;
    }
    @media(max-width:1023px){
      padding: 48px 0;
    }
    @media(max-width:767px){
      .row{
        flex-direction: column;
        align-items: center;
      }
      .img{
        max-width: 360px;
      }
    }
    @media(max-width:479px){
      background: #fff;
      .row{
        padding: 0 0 140px;
        position: relative;   
      }
    }
  }
  &-info{
    margin-right: 68px;
    .h2,h2{
      margin-bottom: 8px;
      letter-spacing: -0.1px;
      font: 700 36px/1.2 "Playfair Display", serif;
    }
    .text{
      font-size: 18px;
    }
    .btn{
      font: 600 18px/36px "Poppins", sans-serif;
      margin-top: 52px;
      height: auto;
    }
    @media(max-width:1023px){
      margin-right: 38px;
      .btn{
        margin-top: 30px;
      }
    }
  }
  @media(max-width:767px){
    &-info{
      margin: 0 0 40px;
      text-align: center;
    }
    &-img{
      max-width: 360px;
      max-height: 258px;
    }
  }
  @media(max-width:479px){
    &-info{
      .btn{
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin: 0;
        left: 50%;
        max-width: 345px;
        transform: translateX(-50%);
      }
    }
  }
}

// looking-appointments

.looking-appointments{
  padding: 70px 0;
  background: #fff;
  &-box{
    background: #F2F2F4;
    box-shadow: 0px 60px 80px -50px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    padding: 32px 65px 65px;
    .dashboard-icon{
      flex:0 0 120px;
    }
    .btn{
      height: 66px;
      min-width: 208px;
      font: 600 24px/46px "Poppins", sans-serif;
      box-shadow: 0px 4px 16px -4px rgba(36, 47, 79, 0.12);
    }
    .row{
      margin-bottom: 40px;
      justify-content: space-between;
      @media #{$information-xsmall-tab}{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        .sub-title{
          text-align: center;
        }
      }
    }
    @media #{$information-xsmall-tab}{
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 32px 24px 64px;
      .global-title_wrap{
        margin-bottom: 40px;
      }
    }
  }
  .text-wrap{
    font-size: 24px;
    margin-bottom: 32px;
  }
  @media #{$information-xsmall-tab}{
    .text-wrap{
      text-align: center;
    }
    .btn{
      margin: 0 auto;
    }
  }
}
