.footer{
    background: #242F4F;
    position: relative;
    z-index: 10;
    & *::selection {
        background: rgba(#ffffff, 1);
        -webkit-text-fill-color: #000000;
        color: #000000;
    }
    & *::-moz-selection {
        background: rgba(#ffffff, 1);
        -webkit-text-fill-color: #000000;
        color: #000000;
    }
    &-nav{
        flex:1;
        justify-content: space-between;
        padding-top: 33px;
        max-width: 70%;
        ul{
            display: flex;
            flex-wrap: wrap;
            @media #{$information-xsmall-tab}{
                max-width: 400px;
                margin: 0 auto;
            }
        }
        li{
            flex:0 0 33.333%;
            max-width: 33.333%;
            margin-top: 8px;
            padding: 0 10px;
            a{
                color:#fff;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        @media #{$information-middle-tab}{
            padding-top: 0;
            max-width: 100%;
            li{
                margin-top: 9px;
            }
        }
        @media #{$information-xsmall-tab}{
            li{
                flex:0 0 50%;
                max-width: 50%;
                &:nth-child(1){
                    order: 1;
                }
                &:nth-child(2){
                    order: 7;
                }
                &:nth-child(3){
                    order: 6;
                }
                &:nth-child(4){
                    order: 3;
                }
                &:nth-child(5){
                    order: 2;
                }
                &:nth-child(6){
                    order: 8;
                }
                &:nth-child(7){
                    order: 5;
                }
                &:nth-child(8){
                    order: 4;
                }
            }
        }
        @media #{$information-small-mob}{
            li{
                flex:0 0 100%;
                margin-top: 10px;
                max-width: 100%;
            }
        }
    }
    .container{
        padding: 32px 70px;
        max-width: 1440px;
        > .row{
            justify-content: space-between;
            @media #{$information-middle-tab}{
                flex-direction: column;
                justify-content: flex-start;
            }
        }
        @media #{$information-laptop}{
            padding: 60px 30px;
            max-width: 1230px;
        }
        @media #{$information-table}{
            padding: 40px 30px 50px;
        }
        @media #{$information-mob}{
            padding: 30px 25px;
        }
        @media #{$information-mob-iphone8}{
            padding: 30px 15px;
        }
    }
    &-logo{
        &-wrap{
            min-width: 350px;
            font-family: "Montserrat", sans-serif;
            color: #FEFEFE;
            font-weight: 500;
            @media #{$information-table}{
                min-width: 200px
            }
            @media #{$information-middle-tab}{
                margin: 0 auto 26px;
                max-width: 164px;
                text-align: center;
            }
        }
        img{
            margin-bottom: 16px;
            display: block;
            @media #{$information-middle-tab}{
                margin: 0 auto 16px;
            }
        }
    }
}