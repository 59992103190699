@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon/icomoon.eot?tmka3m');
	src: url('../fonts/icomoon/icomoon.eot?tmka3m#iefix') format('embedded-opentype'), url('../fonts/icomoon/icomoon.ttf?tmka3m') format('truetype'), url('../fonts/icomoon/icomoon.woff?tmka3m') format('woff'), url('../fonts/icomoon/icomoon.svg?tmka3m#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    font-size: 24px;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow-thin:before {
	content: "\e900";
	color: #0f2ed9;
  }
  .icon-schedule:before {
	content: "\e901";
  }
  .icon-warning-black:before {
	content: "\e902";
  }
  .icon-chevron:before {
	content: "\e981";
  }
  .icon-minimize-black:before {
	content: "\e982";
  }
  .icon-error-black:before {
	content: "\e983";
  }
  .icon-apple .path1:before {
	content: "\e903";
	color: rgb(0, 0, 0);
  }
  .icon-apple .path2:before {
	content: "\e904";
	margin-left: -1em;
	color: rgb(255, 255, 255);
  }
  .icon-arrow-left:before {
	content: "\e905";
  }
  .icon-arrow-right:before {
	content: "\e906";
  }
  .icon-bill .path1:before {
	content: "\e907";
	color: rgb(188, 204, 235);
  }
  .icon-bill .path2:before {
	content: "\e908";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-bill .path3:before {
	content: "\e909";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-bill .path4:before {
	content: "\e90a";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-blog .path1:before {
	content: "\e90b";
	color: rgb(188, 204, 235);
  }
  .icon-blog .path2:before {
	content: "\e90c";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-blog .path3:before {
	content: "\e90d";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-calendar .path1:before {
	content: "\e90e";
	color: rgb(188, 204, 235);
  }
  .icon-calendar .path2:before {
	content: "\e90f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-calendar .path3:before {
	content: "\e910";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-calendar .path4:before {
	content: "\e911";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-calendar .path5:before {
	content: "\e912";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-calendar .path6:before {
	content: "\e913";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-card .path1:before {
	content: "\e914";
	color: rgb(188, 204, 235);
  }
  .icon-card .path2:before {
	content: "\e915";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-card .path3:before {
	content: "\e916";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-card .path4:before {
	content: "\e917";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-card .path5:before {
	content: "\e918";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-card .path6:before {
	content: "\e919";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-checkmark-circle .path1:before {
	content: "\e91a";
	color: rgb(188, 204, 235);
  }
  .icon-checkmark-circle .path2:before {
	content: "\e91b";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-chevron-down:before {
	content: "\e91c";
  }
  .icon-contacts .path1:before {
	content: "\e91d";
	color: rgb(188, 204, 235);
  }
  .icon-contacts .path2:before {
	content: "\e91e";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-contacts .path3:before {
	content: "\e91f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-contacts .path4:before {
	content: "\e920";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-cross:before {
	content: "\e921";
  }
  .icon-dashboard .path1:before {
	content: "\e922";
	color: rgb(188, 204, 235);
  }
  .icon-dashboard .path2:before {
	content: "\e923";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-dashboard .path3:before {
	content: "\e924";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-dashboard .path4:before {
	content: "\e925";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-dashboard .path5:before {
	content: "\e926";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-dashboard .path6:before {
	content: "\e927";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-doctor .path1:before {
	content: "\e928";
	color: rgb(188, 204, 235);
  }
  .icon-doctor .path2:before {
	content: "\e929";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-doctor .path3:before {
	content: "\e92a";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-doctor .path4:before {
	content: "\e92b";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-edit:before {
	content: "\e92c";
  }
  .icon-error .path1:before {
	content: "\e92d";
	color: rgb(188, 204, 235);
  }
  .icon-error .path2:before {
	content: "\e92e";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-eye-off .path1:before {
	content: "\e92f";
	color: rgb(188, 204, 235);
  }
  .icon-eye-off .path2:before {
	content: "\e930";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-eye-off .path3:before {
	content: "\e931";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-eye-off .path4:before {
	content: "\e932";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-eye-on .path1:before {
	content: "\e933";
	color: rgb(188, 204, 235);
  }
  .icon-eye-on .path2:before {
	content: "\e934";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-facebook .path1:before {
	content: "\e935";
	color: rgb(24, 119, 242);
  }
  .icon-facebook .path2:before {
	content: "\e936";
	margin-left: -1em;
	color: rgb(246, 245, 245);
  }
  .icon-file .path1:before {
	content: "\e937";
	color: rgb(188, 204, 235);
  }
  .icon-file .path2:before {
	content: "\e938";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-google .path1:before {
	content: "\e939";
	color: rgb(66, 133, 244);
  }
  .icon-google .path2:before {
	content: "\e93a";
	margin-left: -1em;
	color: rgb(52, 168, 83);
  }
  .icon-google .path3:before {
	content: "\e93b";
	margin-left: -1em;
	color: rgb(251, 188, 5);
  }
  .icon-google .path4:before {
	content: "\e93c";
	margin-left: -1em;
	color: rgb(234, 67, 53);
  }
  .icon-help .path1:before {
	content: "\e93d";
	color: rgb(188, 204, 235);
  }
  .icon-help .path2:before {
	content: "\e93e";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-help .path3:before {
	content: "\e93f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-info .path1:before {
	content: "\e940";
	color: rgb(188, 204, 235);
  }
  .icon-info .path2:before {
	content: "\e941";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-location .path1:before {
	content: "\e942";
	color: rgb(188, 204, 235);
  }
  .icon-location .path2:before {
	content: "\e943";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-maintenance .path1:before {
	content: "\e944";
	color: rgb(188, 204, 235);
  }
  .icon-maintenance .path2:before {
	content: "\e945";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-maintenance .path3:before {
	content: "\e946";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-maintenance .path4:before {
	content: "\e947";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-minimize .path1:before {
	content: "\e948";
	color: rgb(75, 82, 94);
	opacity: 0.5;
  }
  .icon-minimize .path2:before {
	content: "\e949";
	margin-left: -1em;
	color: rgb(36, 47, 79);
	opacity: 0.5;
  }
  .icon-patients .path1:before {
	content: "\e94a";
	color: rgb(188, 204, 235);
  }
  .icon-patients .path2:before {
	content: "\e94b";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-patients .path3:before {
	content: "\e94c";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-plus:before {
	content: "\e94d";
	color: #0f2ed9;
  }
  .icon-radar .path1:before {
	content: "\e94e";
	color: rgb(188, 204, 235);
  }
  .icon-radar .path2:before {
	content: "\e94f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-radar .path3:before {
	content: "\e950";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-radar .path4:before {
	content: "\e951";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-radar .path5:before {
	content: "\e952";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-radar .path6:before {
	content: "\e953";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report .path1:before {
	content: "\e954";
	color: rgb(188, 204, 235);
  }
  .icon-report .path2:before {
	content: "\e955";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report .path3:before {
	content: "\e956";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report .path4:before {
	content: "\e957";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report .path5:before {
	content: "\e958";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report2 .path1:before {
	content: "\e959";
	color: rgb(188, 204, 235);
  }
  .icon-report2 .path2:before {
	content: "\e95a";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report2 .path3:before {
	content: "\e95b";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report2 .path4:before {
	content: "\e95c";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report2 .path5:before {
	content: "\e95d";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-report2 .path6:before {
	content: "\e95e";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-report2 .path7:before {
	content: "\e95f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path1:before {
	content: "\e960";
	color: rgb(188, 204, 235);
  }
  .icon-requirements .path2:before {
	content: "\e961";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path3:before {
	content: "\e962";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path4:before {
	content: "\e963";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path5:before {
	content: "\e964";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path6:before {
	content: "\e965";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-requirements .path7:before {
	content: "\e966";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scans .path1:before {
	content: "\e967";
	color: rgb(188, 204, 235);
  }
  .icon-scans .path2:before {
	content: "\e968";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-scans .path3:before {
	content: "\e969";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scans .path4:before {
	content: "\e96a";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scans .path5:before {
	content: "\e96b";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scheduled .path1:before {
	content: "\e96c";
	color: rgb(188, 204, 235);
  }
  .icon-scheduled .path2:before {
	content: "\e96d";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scheduled .path3:before {
	content: "\e96e";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-scheduled .path4:before {
	content: "\e96f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-scheduled .path5:before {
	content: "\e970";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-scheduled .path6:before {
	content: "\e971";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-search .path1:before {
	content: "\e972";
	color: rgb(188, 204, 235);
  }
  .icon-search .path2:before {
	content: "\e973";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-search .path3:before {
	content: "\e974";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-settings .path1:before {
	content: "\e975";
	color: rgb(188, 204, 235);
  }
  .icon-settings .path2:before {
	content: "\e976";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-troubleshooting .path1:before {
	content: "\e977";
	color: rgb(188, 204, 235);
  }
  .icon-troubleshooting .path2:before {
	content: "\e978";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-troubleshooting .path3:before {
	content: "\e979";
	margin-left: -1em;
	color: rgb(188, 204, 235);
  }
  .icon-troubleshooting .path4:before {
	content: "\e97a";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-warning .path1:before {
	content: "\e97b";
	color: rgb(188, 204, 235);
  }
  .icon-warning .path2:before {
	content: "\e97c";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-zip .path1:before {
	content: "\e97d";
	color: rgb(188, 204, 235);
  }
  .icon-zip .path2:before {
	content: "\e97e";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-zip .path3:before {
	content: "\e97f";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .icon-zip .path4:before {
	content: "\e980";
	margin-left: -1em;
	color: rgb(75, 82, 94);
  }
  .camera-icon .path1:before {
	content: "\e938";
	margin-left: 0.7em;
	opacity: 0%;
	color: rgb(188, 204, 235);
  }
