.handoff-process {
  span,
  p {
    font-family: "Poppins", sans-serif;
  }
  .semi-bold {
    font-weight: 600;
  }

  .line {
    display: block;
  }

  .how-medmo-works {
    margin-bottom: 16px;
    p {
      color:rgba($dark,0.75);
      & + p{
        margin-top: 22px;
      }
    }
    .info {
      font-size: 14px;
      padding-bottom: 24px;

      .circle-container {
        margin-right: 16px;
        .circle {
          width: 24px;
          height: 24px;
          background: #f2f2f4;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;

          .number {
            font: 600 16px/24px "Poppins", sans-serif;
            color: #A3A4A6;
          }
        }
      }

      .texts {
        padding-top: 3px;
        .point-title {
          padding-bottom: 9px;
        }
      }
    }
  }
  .request-warning-text{
    padding: 0 0 0 48px;
    .btn-row{
      justify-content: flex-end;
    }
  }

  .warning-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
    background: url(../assets/svg/warning.svg);
  }

  .request-id {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
    @media #{$information-xsmall-tab} {
      flex: 0 0 100%;
    }
    @media #{$information-mob-iphone8} {
      margin-bottom: 24px;
    }

    .title,
    .num {
      font-family: "Poppins";
      font-style: normal;
    }
    .title {
      font-weight: 400;
      font-size: 18px;
    }
    .num {
      font-size: 24px;
    }
  }

  .red{
    color:#FF3554;
    a{
      color:#0F2ED9;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }

  @media(max-width:730px){
    &.request-type{
      padding: 0 15px;
      .request-warning-text{
        .btn-row{
          justify-content: flex-start;
        }
      }
    }
  }
  @media(max-width: 570px){
    &.request-type{
      .request-warning-text{
        padding: 0 0 0 32px;
      }
    }
  }
}

.continue-as-a-guest {
  cursor: pointer;
  color: #0f2ed9;
  text-decoration: underline;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
}

.or-container {
  display: flex;
  align-items: center;

  .divider {
    width: 100%;
    border-color: black;
  }

  .text {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.size {
  font-size: 16px;
}

@media #{$information-mob-iphone8} {
  .size {
    font-size: 14px;
  } 
}

// patient ping process/
.patient-ping{
  &_header{
    color:$blue;
    margin-bottom: 32px;
    h2{
      margin-bottom: 0;
    }
  }
  &_content{
    color:$base;
    &_item{
      font-size: 16px;
      margin-bottom: 32px;
      ul,ol{
        li{
          position: relative;
          padding-left: 22px;
          &::before{
            content: "";
            border-radius: 50%;
            width: 4px;
            background: $base;
            height: 4px;
            position: absolute;
            left: 8px;
            top:7px;
          }
        }
        li+li{
          margin-top: 8px;
        }
      }
      ol{
        counter-reset: counter;
        li{
          counter-increment: counter;
          &::before{
            content: counter(counter) '.';
            font-size: 14px;
            background: none;
            top:0;
            left: 4px;
            border-radius: 0;
            width: auto;
            height: auto;
          }
        }
      }
      p{
        margin-bottom: 8px;
        strong{
          font-weight: 700;
        }
      }
    }
  }
}

.roi-form{
  .text-form{
    color: $base;
    margin-bottom: 32px;
  }
  .rs-radio-group{
    margin-bottom: 32px;
    .rs-radio-inline{
      margin-left: 0;
      .rs-radio-checker{
        padding-left: 40px;
      }
      .rs-radio-control{
        width: 18px;
        height: 18px;
        &:before{
          border-radius: 2px;
        }
        [type=radio]{
          width: 18px;
          height: 18px;
          &:focus{
            & ~ .rs-radio-inner:before{
              box-shadow: none;
            }
          }
        }
        .rs-radio-inner{
          &::before{
            width: 18px;
            height: 18px;
            border-radius: 2px;
            background: #F6F7F8;
            border: 1px solid #BABBBE;
          }
          &::after{
            width: 18px;
            height: 18px;
            border-radius: 2px;
          }
        }
      }
      &.rs-radio-checked{
        .rs-radio-control::before{
          transform: none;
        }
        .rs-radio-inner{
          &::after{
            margin: 0;
            left: 2px;
            top:-1px;
            background: url("data:image/svg+xml,%0A%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1701_38327)'%3E%3Cg clip-path='url(%23clip1_1701_38327)'%3E%3Cpath d='M12 1L11 2H15V7L16 6V1H12Z' fill='%23EDEEF1'/%3E%3C/g%3E%3Cpath d='M2 8.39844L6 12.3984L16 1.39844' stroke='%23242F4F' stroke-width='3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1701_38327'%3E%3Crect width='18' height='15' fill='white'/%3E%3C/clipPath%3E%3CclipPath id='clip1_1701_38327'%3E%3Crect width='5' height='6' fill='white' transform='translate(11 1)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat;
          }
          &::before{
            background: #EDEEF1;
          }
        }
      }
      & + .rs-radio-inline{
        margin-left: 26px;
      }
    }
    .rs-radio{
      font-size: 16px;
    }
  }
}