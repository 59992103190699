.header{
    height: 80px;
    position: fixed;
    top:0;
    left: 0;
    background: $white;
    width: 100%;
    z-index: 12;
    .btn-menu{
        display: none;
    }
    .btn-menu_sidebar{
        display: none;
    }
    > .container{
        padding: 18px 70px;
        max-width: 1440px;
        > .row{
            justify-content: space-between;
            align-items: center;
        }
    }
    &-logo{
        margin-right: 50px;
    }
    .solv-logo{
        width: 60px;
        margin-right: auto;
        position: relative;
        &::after{
            content: "";
            width: 1px;
            height: 26px;
            background: #d2d3d5;
            position: absolute;
            left: -25px;
            top: -1px;
        }
    }
    .сleerly-logo{
        margin: 0 0 0 auto;
        @media #{$information-mob}{
            max-width: 105px;
        }
    }
    &-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        div.header-nav_link, a.header-nav_link{
            height: 40px;
            margin-left: 90px;
            line-height: 18px;
            min-width:164px;
            font-weight: 600;
            border: 1px solid #244C4F;
            &.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu{
                top:125%;
            }
            .rs-btn-subtle:focus,
            .rs-btn-subtle.rs-btn-focus,
            .rs-btn-subtle:hover{
                background: none;
            }
            .rs-dropdown-menu{
                top:125%;
                padding: 0;
                width: 100%;
                overflow: hidden;
                box-shadow: 0px 5px 7px rgba(46, 43, 43, 0.3);
                border-radius: 60px;
                min-width: 125px;
                height: 40px;
                .rs-dropdown-item{
                    padding: 11px 20px;
                    text-align: center;
                    color: #242F4F;
                    font: 500 16px/18px 'Poppins', sans-serif;
                    background: #F2F2F4;
                    &:hover{
                        background: #e4ebf7;
                        color: #242F4F;
                    }
                }
            }
            .rs-btn{
                position: absolute;
                left: 0;
                top:0;
                width: 100%;
                height: 100%;
                display: block;
                color: #FEFEFE;
                padding: 10px 25px;
                background-color: transparent;
                font: 600 16px/18px 'Poppins', sans-serif;
                > div{
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .rs-dropdown-toggle-caret{
                    display: none;
                }
            }
        }
        &_menu{
            li{
                margin: 0 20px;
            }
            a{
                padding: 0 20px;
                display: inline-block;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        @media #{$information-table}{
            margin-left: auto;
            &_link{
                .rs-dropdown-menu{
                    left: auto!important;
                    right: 0!important;
                }
            }
        }
    }
    &.header-auth{
        div.header-nav_link{
            min-width: 240px;
            background: $blue;
            color:#FEFEFE;
            &:hover{
                background: #505972;
                border: 1px solid #505972;
            }
            @media #{$information-small-notebook}{
                min-width: 200px;
            }
        }
    }
    &.header-ic-portal{
        .header-nav_link{
            min-width:172px;
            border-color: #FF3554;
            color:#FF3554;
        }
    }
    @media #{$information-laptop}{
        > .container{
            padding: 20px 30px;
            max-width: 1170px;
        }
        &-logo{
            margin-right: 40px;
        }
        .solv-logo{
            &::after{
                left: -19px;
            }
        }
        &-nav{
            div.header-nav_link{
                margin-left: 30px;
            }
            &_menu{
                li{
                    margin: 0 15px;
                }
                a{
                    padding: 0 10px;
                }
            }
        }
    }
    @media #{$information-notebook}{
        padding-left: 72px;
        &-nav{
            div.header-nav_link{
                margin-left: 20px;
            }
            &_menu{
                li{
                    margin: 0 15px;
                }
                a{
                    padding: 0 15px;
                }
            }
        }
    }
    @media #{$information-small-notebook}{
        &-logo{
            margin-right: 30px;
        }
        .solv-logo{
            &::after{
                left: -15px;
            }
        }
        &_link{
            margin-left: 15px;
            min-width: 200px;
        }
        &-nav{
            &_menu{
                li{
                    margin: 0 10px;
                }
                a{
                    padding: 10px;
                }
            }
        }
    }
    @media #{$information-table}{
        height: 64px;
        padding-left: 0;
        &-logo{
            position: absolute;
            left: 20px;
            top:15px;
        }
        .solv-logo{
            position: absolute;
            left: 175px;
            top: 18px;
            &::after{
                left: -16px;
            }
        }
        &.header-auth{
            .header-logo{
                left: 50%;
                top:17px;
                margin: 0;
                transform: translateX(-50%);
            }
            .btn-menu{
                &_sidebar{
                    display: block;
                }
            }
            &.header-partner{
                .header-logo{
                    left: calc(50% - 55px);
                }
                .solv-logo{
                    top:20px;
                    left: calc(50% + 65px);
                    &::after{
                        left: -15px;
                    }
                }
            }
            .header-nav div.header-nav_link{
                min-width: 36px;
                height: 36px;
                width: 36px;
                padding: 0;
                border-radius: 50%;
                .rs-btn{
                    line-height: 30px;
                    padding: 3px 0;
                    height: 36px;
                    width: 36px;
                    div{
                        text-align: center;
                        span{
                            font-size: 0;
                            display: inline-block;
                            position: relative;
                            right: -2px;
                            &:last-child{
                                right: auto;
                                left: -3px;
                                position: relative;
                            }
                            &::first-letter{
                                font-size: 18px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        > .container{
            padding: 12px 30px;
            & > .row{
                justify-content: center;
            }
        }
        &-nav{
            &_menu{
                flex-direction: column;
                li{
                    margin: 0;
                }
            }
            &_link{
                min-width: 123px;
            }
        }
    }
    @media #{$information-middle-tab}{
        > .container{
            padding: 12px 20px;
        }
        .btn-menu{
            right: 30px;
            &_sidebar{
                left: 30px;
                right: auto;
            }
        }
    }
    @media #{$information-mob}{
        > .container{
            padding: 10px 15px;
        }
        .btn-menu{
            right: 15px;
            &_sidebar{
                left: 15px;
                right: auto;
            }
        }
        &-logo{
            left: 15px;
            max-width: 96px;
            top:20px;
        }
        .solv-logo{
            top:21px;
            width: 50px;
            left: 140px;
            &::after{
                height: 22px;
                left: -14px;
                top: -1px;
            }
        }
        &-nav{
            div.header-nav_link, a.header-nav_link{
                min-width: 136px;
            }
        }
        &.header-auth{
            .header-logo{
                left: 50%;
                top:16px;
                margin: 0;
                transform: translateX(-50%);
            }
            &.header-partner{
                .header-logo{
                    left: calc(50% - 50px);
                }
                .solv-logo{
                    top:18px;
                    left: calc(50% + 56px);
                    &::after{
                        left: -16px;
                    }
                }
            }
        }
    }
    @media #{$information-small-mob}{
        &.header-auth{
            &.header-partner{
                .solv-logo{
                    left: calc(50% + 52px);
                }
            }
        }
        &-nav{
            div.header-nav_link, a.header-nav_link{
                min-width: 96px;
                padding: 10px 15px;
            }
        }
        .solv-logo{
            width: 40px;
        }
    }
}

@media #{$information-table}{
    .btn-menu{
        width: 24px;
        display: block;
        height: 24px;
        cursor: pointer;
        &_sidebar{
            position: fixed;
            top:17px;
            left: 30px;
            display: block;
            z-index: 12;
        }
        span{
            display: block;
            position: absolute;
            height: 2px;
            background: $blue;
            opacity: 1;
            left: 2px;
            top:5px;
            right: 2px;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }
        & span:nth-child(1) {
            top: 5px;
        }
        & span:nth-child(2),
        & span:nth-child(3) {
            top: 11px;
        }
        
        & span:nth-child(4) {
            top: 17px;
        }
        &.open span:nth-child(1) {
            top: 11px;
            width: 0%;
            left: 50%;
        }
        
        &.open span:nth-child(2) {
            transform: rotate(45deg);
        }
        
        &.open span:nth-child(3) {
            transform: rotate(-45deg);
        }
        
        &.open span:nth-child(4) {
            top: 11px;
            width: 0%;
            left: 2px;
        }            
    }
}

@media #{$information-mob}{
    .btn-menu_sidebar{
        left: 15px;
        top:17px;
    }
}