$browserSize: 14px; // Default

@function rem($pixels, $context: $browserSize) {
  @return #{$pixels/$context}rem;
}

%img-render {
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

@mixin stretching {
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

$information-laptop: "only screen and (max-width : 1500px)";
$information-notebook: "only screen and (max-width : 1379px)";
$information-small-notebook: "only screen and (max-width : 1279px)";
$information-table: "only screen and (max-width : 1023px)";
$information-middle-tab: "only screen and (max-width : 767px)";
$information-small-tab: "only screen and (max-width : 699px)";
$information-xsmall-tab: "only screen and (max-width : 639px)";
$information-mob: "only screen and (max-width : 570px)";
$information-mob-iphone10: "only screen and (max-width : 479px)";
$information-mob-iphone8: "only screen and (max-width : 400px)";
$information-small-mob: "only screen and (max-width : 360px)";

// Colors
$base:#040508;
$dark:#1C243C;
$blue:#242F4F;
$blueLight:#0F2ED9;
$yellowLight: #FFF4D7;
$white:#FEFEFE;
$red:#C2002B;
$black: #000;
$lightGray: #f2f6fe;

// Layout
$max-width: 100%;
$easing: cubic-bezier(.25,.75,.5,1.25);

@mixin transition($type, $time) {
  transition: $type $time $easing;
}

$trs: 0.3s;
$brs: 4px;
