.row{
  display: flex;
}
.row-wrap{
  flex-wrap: wrap;
}
.row-nowrap{
  flex-wrap: nowrap!important;
}
.row-column{
  flex-direction: column;
}
.justify-content-between{
  display: flex;
  justify-content: space-between;
}
.align-items-end{
  align-items: flex-end;
  display: flex;
}
.align-items-center{
  display: flex;
  align-items: center;
}
.justify-content-end{
  display: flex;
  justify-content: flex-end;
}
.justify-content-between_align-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justify-content-center{
  display: flex;
  justify-content: center;
}
.flex1{
  flex:1;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.row-input50{
  margin: 0 -8px;
  @media #{$information-small-mob} {
    margin: 0;
  }
}
.container{
  max-width: 1170px;
  padding: 0 30px;
  margin:0 auto;
}
