:root {
  --app-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $base;
  min-width: 320px;
  background: $white;
  font: 400 14px/1.4 'Poppins', sans-serif;
}

.wrapper {
  position: relative;
  overflow: clip;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  padding-top: 84px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media #{$information-table} {
    padding-top: 60px;
  }
}

@import 'header';
@import 'footer';