.page-content{
    padding: 32px 0 54px;
    flex:1;
    &-wrap{
        color:#040508;
        max-width: 1380px;
        margin: 0 auto;
        padding: 0 40px;
        font-size: 24px;
        > *{
            margin-bottom: 24px;
        }
        p + p{
            margin-top: 0;
        }
    }
    &_quality-page {
        p {
            margin-bottom: 24px;
        }
    }
    h1,&-h1{
        font:700 48px/1.4 'Playfair Display', serif;
        margin-bottom: 24px;
        letter-spacing: -0.025em;
    }
    h2,&-h2{
        font:600 24px/1.4 'Poppins', serif;
        color: #FF8A35;
    }
    &-data{
        color: #244C4F;
        font-weight: 600;
    }
    a.link {
        font-size: 18px;
    }
    @media #{$information-laptop}{
        &-wrap{
            max-width: 1170px;
            padding: 0 30px;
        }
    }
    @media #{$information-notebook}{
        &-wrap{
            padding-left: 70px;
        }
    }
    @media(max-width:1279px){
        &-wrap{
            padding-left: 100px;
        }
        &-wrap,h2,&-h2{
            font-size: 20px;
        }
        h1,&-h1{
            font-size: 36px;
        }
    }
    @media(max-width:1023px){
        &-wrap{
            padding-left: 40px;
        }
    }
    @media(max-width:767px){
        padding: 22px 0 44px;
        &-wrap{
            padding: 0 30px;
        }
        &-wrap,h2,&-h2{
            font-size: 18px;
        }
        h1,&-h1{
            font-size: 32px;
        }
    }
    @media(max-width:379px){
        padding: 22px 0 34px;
        &-wrap{
            padding: 0 20px;
        }
        &-wrap,h2,&-h2{
            font-size: 16px;
        }
        h1,&-h1{
            font-size: 28px;
        }
    }
}