/* thumbnail classes */
.thumbnail-wrapper {
  width: 80px;
  height: 85px;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  touch-action: manipulation;
  align-items: center;
}

.thumbnail-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto auto;
  object-position: center;
}

/* previewer classes */
.preview-wrapper {
  width: 100%;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  flex-direction: column;
  touch-action: manipulation;
}

/* icon buttons */
.preview-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 24px;
  text-align: center;
  border-radius: 3px;
  touch-action: manipulation;
  background-color: transparent;
}

.preview-button:hover,
.preview-button:active {
  cursor: pointer;
  touch-action: manipulation;
  background-color: rgba(255, 255, 255, 0.25);
}

.preview-button:disabled {
  opacity: 0.5;
  cursor: default;
  touch-action: manipulation;
}

.preview-bar {
  color: #fff;
  width: 100%;
  height: 52px;
  display: flex;
  min-height: 52px;
  max-height: 52px;
  align-items: center;
  align-content: center;
  background-color: #f8f8f8;
  touch-action: manipulation;
  justify-content: flex-end;
}

.preview-bar-left,
.preview-bar-left {
  padding: 6px;
  display: flex;
  align-items: center;
}

.preview-bar-left button {
  margin-right: 3px;
}

.preview-bar-left span.preview-pagecount {
  margin-left: 6px;
}

.preview-bar-right button {
  margin-left: 6px;
}

.preview-icons {
  right: 12px;
  z-index: 999;
  bottom: 24px;
  padding: 6px;
  display: flex;
  position: absolute;
  border-radius: 3px;
  flex-direction: column;
  touch-action: manipulation;
  background-color: rgba(0, 0, 0, 0.5);
}

.preview-icons button {
  margin-top: 6px;
}

.preview-icons button:first-child {
  margin-top: 0;
}

.preview-content {
  width: 100%;
  display: flex;
  overflow: scroll;
  position: relative;
  background-color: #eeeeee;
  touch-action: manipulation;
  height: calc(80vh - 100px);
}

.preview-content .preview-file {
  margin: auto;
  padding: 12px;
}

.preview-content .preview-file img {
  transition-delay: 0ms, 0ms, 0ms, 250ms;
  transition-duration: 250ms, 250ms, 250ms, 350ms;
  transition-property: width, height, transform, opacity;

  transform-origin: center center;
}

.preview-pdf-page {
  margin-bottom: 12px;
}

.media-thumbnail {
  overflow: hidden;
  pointer-events: none;
  background-size: cover;
  touch-action: manipulation;
  background-position: center;
}

.media-thumbnail img {
  width: 100%;
  height: 100%;
  touch-action: manipulation;
}

.media-thumbnail .react-pdf__Page {
  padding: 0;
  touch-action: manipulation;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.file-viewer {
  width: 90%;
  top: 0;
  animation: unset;
}

.file-viewer .rs-modal-body {
  max-height: 80vh !important;
}

.react-doc-viewer {
  text-align: center;
}